@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #17181a;
}
.container {
  width: 90%;
  margin: auto;
}
.d_flex {
  display: flex;
  justify-content: space-between;
}
.f_flex {
  display: flex;
}
a {
  text-decoration: none;
  color: black;
}
li {
  list-style: none;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

button {
  border: none;
  outline: none;
  background-color: none;
}

.btn_shadow {
  padding: 18px 25px;
  border-radius: 6px;
  transition: 0.3s all ease;
  cursor: pointer;
  color: #878e99;
  transition: 0.5s;
}
.btn_shadow {
  background: linear-gradient(145deg, #3c3e41, #242527);
  box-shadow: 4px 4px 8px #000000, -4px -4px 8px #0f0f0f;
}
.btn_shadow:hover {
  background: #28d3d3;
  color: #17181a;
  transform: translateY(-10px);
}
.box_shodow {
  background: linear-gradient(145deg, #3c3e41, #242527);
  box-shadow: 4px 4px 8px #000000, -4px -4px 8px #0f0f0f;
  border-radius: 10px;
}
.row,
.left,
.right {
  width: 50%;
}
.top {
  margin-top: 80px;
}
.mtop {
  margin-top: 40px;
}
.heading {
  margin: 50px 0 50px 0;
}
.heading h4 {
  color: #ffffff;
  letter-spacing: 2px;
  font-weight: 400;
}
.heading h1 {
  font-size: 60px;
  color: #28d3d3;
}
h1 {
  color: #3c3e41;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 35px;
}
p {
  color: #3c3e41;
}
.text-center {
  text-align: center;
}
.primary_color {
  color: #28d3d3;
}
textarea,
input {
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  outline: none;
  border: 3px solid #242527;
  margin-bottom: 20px;
  background-color: #242527;
  color: #878e99;
}
/*----------Footer---------*/
footer {
  min-height: 100px;
  margin-top: 30px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
footer p {
  color: #878e99;
  margin: 30px 0 30px 0;
}
footer button{
  margin: auto;
  max-width: 80%;
  margin-bottom: -30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}
::-webkit-scrollbar{
	width: 1.2rem;
}
::-webkit-scrollbar-track {
	background-color: transparent;
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar-thumb{
	background-color: #878e99;
	border-radius: 20px;
	border: 5px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover{
	background-color: #28d3d3;
}
/*----------Footer---------*/
@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .left,
  .right {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .grid {
    grid-template-columns: 1fr;
  }
}