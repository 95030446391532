.hero {
  position: relative;
}
.hero h3 {
  font-weight: 100;
  letter-spacing: 2px;
  font-size: medium;
  color: #ffffff;
}
.hero h1 {
  margin-top: 20px;
  font-size: 60px;
  font-weight: bold;
  color: #878e99;
  line-height: 60px;
}

.hero h1 span {
  color: #28d3d3;
}
.hero h2 {
  font-size: 50px;
  font-weight: bold;
  color: #28d3d3;
}
.hero h2 span {
  color: #ffffff;
  font-size: 46px;
}
.hero p {
  color: #878e99;
  font-size: 18px;
  line-height: 30px;
  margin-top: 20px;
}
.hero_btn {
  margin-top: 80px;
  width: 100%;
}
.hero_btn h4 {
  font-weight: 500;
  letter-spacing: 2px;
  color: #878e99;
  font-size: 15px;
  margin: 30px 0 0px 15px;
}
.hero_btn button {
  margin-right: 15px;
  margin-left: 15px;
}
.hero_btn i {
  font-size: 28px;
}
.hero .d_flex{
  justify-content: space-around;
}
.top_hero{
  display: flex;
  margin-bottom: 0;
  margin-top: 40px;
}
.hero .col_1{
  min-width: 100px;
}
.hero .btn_shadow{
  margin-top: 30px;
  margin-bottom: 20px;
}
.hero .tech{
  margin-top: 30px;
  margin-bottom: 20px;
}
.hero_btn button img {
  width: 30px;
  height: 30px;
}
.tech {
  padding: 18px 25px;
  border-radius: 6px;
  transition: 0.3s all ease;
  cursor: default;
  color: #878e99;
  transition: 0.5s;
}
.tech {
  background: linear-gradient(145deg, #3c3e41, #242527);
  box-shadow: 4px 4px 8px #000000, -4px -4px 8px #0f0f0f;
}
.tech:hover {
  background: #28d3d3;
  color: #17181a;
  transform: translateY(-10px);
}
.hero .left {
  min-width: 50%;
  margin-top: 40px;
}
.img-container{
  width: 50%;
}
.to_port_btn{
  font-size: 20px;
  font-weight: 100;
  margin: 30px 30px 0px 0px;
}
.img-container img{
  max-width: 100%;
}
.hero .right {
  max-width: 40%;
  position: relative;
  margin-top: 40px;
}
.hero .right_img {
  /* background: linear-gradient(145deg, #e2e8ec, #ffffff);
  box-shadow: 4px 4px 8px #cbcbcb, -4px -4px 8px #ffffff;
  border-radius: 10px; */

  max-width: 510px;
}
.img-container{
  width: 100%;
}
.hero .right_img img {
  max-width: 610px;
}
@media (max-width: 768px) {
  .hero .right,
  .hero .left {
    width: 100%;

  }
  .img-container{
    margin-top: 60px;
  }
  .hero .col_1{
    text-align: center;
  }
  .hero .top_hero {
    flex-direction: column;
    text-align: center;
  }
  .hero .right_img {
    max-width: 100%;
    height: 54vh;
  }
  .hero h2 span {
    font-size: 38px;
  }
  .to_port_btn{
    font-size: 20px;
    font-weight: 100;
    margin: 30px 0px 0px 0px;
    flex-direction: column;
    display: flex;
    margin: auto;
  }
}
@media (max-width: 490px) {
  .hero h2 span {
    font-size: 30px;
  }
}
