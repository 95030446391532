.Contact{
  padding-top: 50px;
}
.Contact .heading h1{
  font-size: 55px;
}
.Contact .right {
  width: 70%;
  margin: auto;
}

.Contact .content {
  color: #878e99;
}

.Contact .button {
  margin-top: 20px;
}
.Contact button {
  margin-right: 20px;
}
.input_row{
  display: flex;
  justify-content: space-between;
}
.Contact .row {
  width: 48%;
}
.Contact .right {
  padding: 35px;
}
.Contact .input span {
  font-size: 13px;
}
.Contact input {
  margin-top: 10px;
}
.Contact .right button {
  width: 100%;
  color: #878e99;
}
.Contact .right button:hover {
  color: black;
}
.Contact .right button i {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .Contact .d_flex {
    flex-direction: column;
  }
  .Contact .left,
  .Contact .right {
    width: 100%;
    margin-top: 10px;
  }
}
