.Resume{
  padding-top: 90px;
}

.Resume .heading h1 {
  font-size: 55px;
}
.Resume .content {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Resume .box {
  padding: 40px;
}

.Resume p {
  transition: 0.5s;
}
.Resume .box {
  margin-bottom: 40px;
  position: relative;
}
.Resume .btn_shadow:hover {
  transform: translate(0);
}
.Resume .box:hover .btn_shadow {
  background: #28d3d3;
  box-shadow: none;
  color: white;
}

.exp:hover{
  cursor: default;
}
.Resume .box:hover p {
  color: black;
}
.Resume .content-section {
  position: relative;
}
.Resume .left {
  position: relative;
  margin-right: 20px;
}
.Resume .right {
  position: relative;
  margin-left: 20px;
}
.heading h2 {
  margin: auto;
}
.Resume .gray {
  font-size: 20px;
  margin-top: 20px;
  color: #878e99;
}
.heading .gray {
  margin-top: -30px;
}
.job{
  color: #878e99;
  font-size: 14px;
}

.download {
  margin-top: 10px;
  padding: 12px 18px;
  border-radius: 6px;
  transition: 0.3s all ease;
  cursor: pointer;
  color: #878e99;
  transition: 0.5s;
  background: linear-gradient(145deg, #3c3e41, #242527);
  box-shadow: 4px 4px 8px #000000, -4px -4px 8px #0f0f0f;
}

.download:hover {
  background: #28d3d3;
  color: #17181a;
  transform: translateY(-10px);
}

.gpa{
  margin-top: 15px;
  color: #28d3d3;
}

@media (max-width: 768px) {
  .Resume .d_flex {
    flex-direction: column;
  }
  .Resume .right{
    margin: auto;
  }
}
